// Icon SVG Custom
.icon-start {
  background-image: url("../images/icons/start.svg");
}

.icon-person {
  background-image: url("../images/icons/person.svg");
}

.icon-unlock {
  background-image: url("../images/icons/unlock.svg");
}

.icon-credit {
  background-image: url("../images/icons/credit.svg");
}

.icon-chart {
  background-image: url("../images/icons/chart.svg");
}

.icon-health {
  background-image: url("../images/icons/health.svg");
}

.icon-calendar {
  background-image: url("../images/icons/calendar.svg");
}

.icon-help {
  background-image: url("../images/icons/help.svg");
}

.icon-term {
  background-image: url("../images/icons/term.svg");
}

.icon-privace {
  background-image: url("../images/icons/privace.svg");
}

.icon-logout {
  background-image: url("../images/icons/logout.svg");
}

.icon-about-me {
  background-image: url("../images/icons/about_me.svg");
}

.icon-trash {
  background-image: url("../images/icons/trash.svg");
}

.icon-note {
  background-image: url("../images/icons/note.svg");
}

.icon-notification {
  background-image: url("../images/icons/notification.svg");
}

.icon-message-text {
  background-image: url("../images/icons/message_text.svg");
}

.icon-info {
  background-image: url("../images/icons/info.svg");
}

.icon-info-ballon {
  background-image: url("../images/icons/info_ballon.svg");
}

.icon-map-marker {
  background-image: url("../images/icons/map_marker.svg");
}

.icon-lock {
  background-image: url("../images/icons/lock.svg");
}

.icon-map {
  background-image: url("../images/icons/map.svg");
}

.icon-bookmark {
  background-image: url("../images/icons/bookmark.svg");
}

.icon-marker {
  background-image: url("../images/icons/marker.svg");
}

.icon-house-outline {
  background-image: url("../images/icons/house_outline.svg");
}

.icon-phone {
  background-image: url("../images/icons/phone.svg");
}

.icon-email {
  background-image: url("../images/icons/email.svg");
}

.icon-email-2 {
  background-image: url("../images/icons/email_2.svg");
}

.icon-filter {
  background-image: url("../images/icons/filter.svg");
}

.icon-lamp {
  background-image: url("../images/icons/lamp.svg");
}

.icon-person-plus {
  background-image: url("../images/icons/person_plus.svg");
}

.icon-edit {
  background-image: url("../images/icons/edit.svg");
}

.icon-tag {
  background-image: url("../images/icons/tag.svg");
}

.icon-time {
  background-image: url("../images/icons/time.svg");
}

.icon-password {
  background-image: url("../images/icons/password.svg");
}

.icon-hospital {
  background-image: url("../images/icons/hospital.svg");
}

.icon-cam {
  background-image: url("../images/icons/cam.svg");
}

.icon-option-config {
  background-image: url("../images/icons/option_config.svg");
}

.icon-location {
  background-image: url("../images/icons/location.svg");
}

.icon-box {
  background-image: url("../images/icons/box.svg");
}

.icon-package {
  background-image: url("../images/icons/package.svg");
}

.icon-square-mult {
  background-image: url("../images/icons/square_mult.svg");
}

.icon-photo {
  background-image: url("../images/icons/photo.svg");
}

.icon-global {
  background-image: url("../images/icons/global.svg");
}

.icon-identity {
  background-image: url("../images/icons/identity.svg");
}

.icon-document {
  background-image: url("../images/icons/document.svg");
}

.icon-document2 {
  background-image: url("../images/icons/document_2.svg");
}

.icon-document-stamp {
  background-image: url("../images/icons/document_stamp.svg");
}

.icon-gender {
  background-image: url("../images/icons/gender.svg");
}

.icon-number-house {
  background-image: url("../images/icons/number_house.svg");
}

.icon-done {
  background-image: url("../images/icons/done.svg");
}

.icon-close {
  background-image: url("../images/icons/close.svg");
}

.icon-time-approval {
  background-image: url("../images/icons/time_approval.svg");
}

.icon-products {
  background-image: url("../images/icons/products.svg");
}

.icon-search {
  background-image: url("../images/icons/search.svg");
}

.icon-bank {
  background-image: url("../images/icons/bank.svg");
}

.icon-building {
  background-image: url("../images/icons/building.svg");
}

.icon-time-half {
  background-image: url("../images/icons/time_half.svg");
}

.icon-people {
  background-image: url("../images/icons/people.svg");
}
